import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import pcPage from '../views/pcPage.vue';
import mobilePage from '../views/mobilePage.vue';
import RedirectPage from '../views/RedirectPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "pc",
    component:pcPage,
  },
  {
    path: "/mobilePage",
    name: "mobile",
    component:mobilePage,
  },
  {
    path: '/t',
    name: 'RedirectPage',
    component: RedirectPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
