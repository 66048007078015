import axios from 'axios';

// 创建一个 Axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 根据你的实际情况修改 baseURL
  timeout: 60000, // 设置超时时间（单位：毫秒）
});


// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做一些处理，例如添加请求头等
    // config.headers['Authorization'] = 'Bearer ' + token;
    return config
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做一些处理，例如解析数据等
    return response;
  },
  (error) => {
    // 处理响应错
    return Promise.reject(error);
  }
);

export default service;
